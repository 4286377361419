angular.module('fingerink')
    .service('changePasswordModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance = $uibModal.open({
                backdrop: 'static',
                templateUrl: 'modals/changePasswordModal.tpl.html',
                controller: 'changePasswordModalCtrl',
                animation: false,
                size: 'lg'
            });

            return this.modalInstance;
        };

    })
    .controller('changePasswordModalCtrl', function changePasswordModalCtrl($rootScope, $scope, $uibModalInstance, userService, fingerinkSession, swalService) {
        $scope.password = {};
        $scope.close = () => $uibModalInstance.close();

        $scope.updatePassword = function () {
            if ($scope.password.newP == $scope.password.repeat) {
                swalService.requestSwal('¿Estás seguro?', 'Vas a cambiar tu contraseña de acceso a la aplicación, la anterior ya no funcionará.', 'warning',
                    () => userService.changePassword(md5($scope.password.actual), md5($scope.password.newP)).then(() => {
                        $uibModalInstance.close();
                        swalService.basicSwal('¡Correcto!', '¡Se ha cambiado la contraseña!', 'success');
                        fingerinkSession.renoveToken();
                        $uibModalInstance.close();
                    }, response => {
                        $uibModalInstance.close();
                        if (response.status === 417) {
                            swalService.basicSwal('Datos Incorrectos', 'La contraseña introducida no es correcta', 'error');
                        } else {
                            swalService.basicSwal('Algo ha ido mal', 'Ha habido un error actualizando la contraseña', 'error');
                        }
                    }));
            } else {
                walService.basicSwal('Algo ha ido mal del todo', 'La repetición de la contraseña no coincide con la nueva contraseña', 'error');
            }
        };

    });
